<template>
    <seccion-datos :titulo="estadopendientedatos" @cambios="guardar()">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.nombre') }}</label>
                    <input type="text" class="form-control nombre_completo dato" v-model="nombre_especialidad_pendiente" >
                </div>
            </div>            
        </div>        
    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: {
        id: Number,
        
    },
    data() {
        return {
            nombre_especialidad_pendiente: '',
            estadopendientedatos: "Datos", 
            estado_pendiente: Object
        }
    },
    methods: {
        async cargardatos() {
           
            this.$store.dispatch('cambiarCargando', true);
            const api = new PwgsApi;
            const datos = await api.get('tipos-estado-pendiente/' + this.id);
            this.estado_pendiente = datos.datos;
            this.$store.dispatch('cambiarCargando', false);
            this.nombre_especialidad_pendiente = this.estado_pendiente.nombre_especialidad_pendiente;
            
          
        },
        async guardar() {
            /* put  modulos/pwgsapi/index.php/bancos/:id */
            const api = new PwgsApi;
            const datos = { nombre_especialidad_pendiente : this.nombre_especialidad_pendiente };
            if (this.id > 0) {
                try {
                    this.$store.dispatch('cambiarGuardando', true);
                    await api.put('tipos-estado-pendiente/' + this.id, datos);
                    this.$store.dispatch('cambiarGuardando', false);
                    this.$parent.obtenerEstadoPendiente(this.id);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                }
                  catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
            else {
                /* post  modulos/pwgsapi/index.php/bancos */
                try {
                    let res;
                    res = await api.post('tipos-estado-pendiente/', datos);
                    this.$router.push('/estadospendientes/' + res.id);
                }
                catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
        },
        comprobar() { }
    },
    mounted() {
        this.cargardatos();
    }
}
</script>