<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div v-if="(id > 0)" class="col-sm-6">
                        <h1>{{ $t('general.Estado Pendiente') }}</h1>
                    </div>
                    <div v-else class="col-sm-6">
                        <h1>{{ $t('general.Crear Estados Pendientes') }}</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <!-- Ocultar esta columna en la creación -->
                <div v-if="(id > 0)" class="col-md-2">
                    <!-- Datos correos -->
                    <div class="card bg-gradient-primary">
                        <div class="card-body box-profile">
                            <h3 class="profile-username text-center">{{ estado_pendiente.nombre_especialidad_pendiente }}</h3>
                        </div>
                    </div>
                    <!--<div class="callout callout-info">
                  <h5>Última modificación</h5>
                    <p>15/12/2021 15:35h por soporte2</p>
                    </div>-->
                </div>
                <div class="col-md-6">
                    <div v-if="(id > 0)" class="card card-default card-tabs">
                        <div class="card-header p-1 pt-1">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a class="nav-link active" href="#datos_estado_pendiente" data-toggle="tab">{{
                                    $t('general.datos') }}</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-content">
                        <div class="active tab-pane" id="datos_estado_pendiente">
                            <estado-pendiente-datos :id="id" :key="id" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import EstadoPendienteDatos from './EstadoPendienteDatos.vue'
export default {
    components: {
        'estado-pendiente-datos': EstadoPendienteDatos,
    },
    props: ['id'],
    data() {
        return {
            estado_pendiente: [],
        }
    },
    methods: {
        async obtenerEstadoPendiente(id) {
            /* get  modulos/pwgsapi/index.php/bancos/:id */
            const api = new PwgsApi;
            const datos = await api.get('tipos-estado-pendiente/' + id);
            this.estado_pendiente = datos.datos;
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/estadospendientes/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = this.estado_pendiente.nombre_especialidad_pendiente;
                    this.$store.getters.ventanas[i].alt = "Estado Pendiente: " + this.estado_pendiente.nombre_especialidad_pendiente;
                }
            }
            /**/
        },

        cambios() {
            this.obtenerEstadoPendiente(this.id);
        },
        recargar() {
            this.obtenerEstadoPendiente(this.id);
        },
    },
    mounted() {
        this.obtenerEstadoPendiente(this.id);
    },
    watch: {
        id() {
            this.obtenerEstadoPendiente(this.id);
        },
        $route() {
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/estadospendientes/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = this.estado_pendiente.nombre_especialidad_pendiente;
                    this.$store.getters.ventanas[i].alt = "Estado Pendiente: " + this.estado_pendiente.nombre_especialidad_pendiente;
                }
            }
            /**/
        }
    }
}
</script>
